:root {
  --font-small: 12px;
  --font-medium: 16px;
  --button-border-radius: 4px;
  --primary-button-color: rgba(0, 52, 76, 1);
  --primary-button-hovered: rgba(0, 52, 76, 0.8);
  --secondary-button-hovered: #EBEFF1;
  --primary-disabled-button-color: #ccc;
  --primary-disabled-button-text-color: #888;
  --link-color: #337ab7;
  --link-visited: #477cb4;
  --grey-text: #868e96;
  --input-base-color: rgba(0, 0, 0, .87);
  --input-placeholder-color: rgba(0, 0, 0, .6);
  --input-error-color: rgba(244, 67, 54, 1);
  --input-highlighted-color: rgba(33, 150, 243, 1);
  --input-inputted-text-color: #004B87;
  --invalid-error-box-text: #721c24;
  --invalid-error-box-background: #f8d7da;
  --invalid-error-box-shadow: rgba(197, 187, 188, 1);
  --fix-transform-wiggle-for-label-margin: -1%;
  --label-transition-animation-time: 0.2s;
}

@font-face {
  font-family: 'Roboto', Helvetica, sans-serif;
  src: url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
}

.button {
  text-transform: none;
  transition: all .2s ease-in-out;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: var(--button-border-radius);
  border: none;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 18%);
  width: fit-content;
  display: inline-block;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: var(--pf-global--spacer--form-element) var(--pf-global--spacer--md);
}

.button:focus {
  box-shadow: 0 0 0 3px rgba(0, 145, 218, .5) !important;
}

.button:hover {
  background-color: var(--primary-button-hovered) !important;
  color: #fff;
}

.primary-button {
  background-color: var(--primary-button-color);
  color: #f9f7f7;
}

.primary-button:hover {
  background-color: var(--primary-button-hovered);
}

.primary-button:disabled,
.primary-button[disabled] {
  background: var(--primary-disabled-button-color);
  color: var(--primary-disabled-button-text-color);
}

.primary-button:focus {
  box-shadow: 0 0 0 3px rgba(0, 145, 218, .5);
}

.tertiary-button {
  background-color: transparent;
  color: var(--primary-button-color);
  transition: all .2s ease-in-out;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: var(--button-border-radius);
  border: none;
  outline: none;
  text-decoration: none;
  width: fit-content;
  display: inline-block;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.tertiary-button:hover {
  background-color: var(--secondary-button-hovered);
  color: var(--primary-button-color) !important;
  border: none;
  outline: none;
  text-decoration: none;
}

.secondary-button {
  background-color: white;
  color: var(--primary-button-color);
  transition: all .2s ease-in-out;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: var(--button-border-radius);
  border: none;
  outline: none;
  text-decoration: none;
  width: fit-content;
  display: inline-block;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.secondary-button:hover {
  background-color: white;
  color: var(--primary-button-color);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 36%);
  outline: none;
  text-decoration: none;
}

.tiny-button {
  padding: 1px 5px;
  line-height: 1.5;
  border-radius: var(--button-border-radius);
  font-size: 11px;
  font-weight: 500;
}

.small-button {
  padding: 2px 6px;
  line-height: 1.5;
  border-radius: var(--button-border-radius);
  font-size: 11px;
  font-weight: 500;
}

.large-button {
  padding: 6px 16px;
  line-height: 1.5;
  border-radius: var(--button-border-radius);
  font-size: 14px;
  font-weight: 500;
}

.huge-button {
  padding: 6px 16px;
  line-height: 1.5;
  border-radius: var(--button-border-radius);
  font-size: 14px;
  font-weight: 500;
}

/* Error and Warning Messages */
.error-message,
.invalid-email-format {
  display: none;
  color: var(--input-error-color);
  font-size: var(--font-small);
  position: absolute;
  transform: translateY(-100%);
  margin-top: 0.25em;
}

.invalid-credentials-box,
.invalid-credentials-box-locked-out {
  background-color: var(--invalid-error-box-background);
  color: var(--invalid-error-box-text);
  font-size: var(--font-medium);
  border-radius: 5px;
  padding: 12px;
  line-height: normal;
  box-shadow: 0px 3px 3px -1px var(--invalid-error-box-shadow);
}

/* Login Inputs - Default and Valid Inputs */
.login-pf body {
  background: url("../img/keycloak-bg.png") no-repeat center center fixed;
  background-size: cover;
  height: 100%;
}

input:not(#kc-login),
input:valid:focus:not(#kc-login) {
  font-size: var(--font-medium 16px);
  border-width: 0% !important;
  border-color: #fff !important;
  box-shadow: 0px 1.5px 0px 0px var(--input-placeholder-color);
  height: calc(var(--pf-c-form-control--FontSize) * var(--pf-c-form-control--LineHeight) + var(--pf-c-form-control--BorderWidth) * 2 + var(--pf-c-form-control--PaddingTop) + var(--pf-c-form-control--PaddingBottom));
  padding: 5px 0;
}

input:valid:focus:not(#kc-login) {
  box-shadow: 0px 1.5px 0px 0px var(--input-highlighted-color);
}

.hkjs--touched:invalid:not(:focus):not(#kc-login),
input:invalid:focus:not(#kc-login) {
  box-shadow: 0px 1.5px 0px 0px var(--input-error-color);
}

/* when autofilling, we want to show the default styling */
/* when the placeholder is not shown, by default, set the input colour to default*/
input:autofill,
input:not(:placeholder-shown) {
  box-shadow: 0px 0.5px 0px 0px var(--input-base-color);
  color: var(--input-inputted-text-color);
}

input:autofill+.animated-label {
  color: var(--input-placeholder-color);
  font-size: var(--font-small);
  bottom: 50px;
  left: 4px;
  transition: all var(--label-transition-animation-time) ease-in-out;
}

input:hover {
  box-shadow: 0px 1.5px 0px 0px var(--input-base-color);
}

input:focus-visible {
  outline: none;
}

.animated-label {
  font-size: var(--font-medium);
  font-weight: 400;
  font-family: 'Roboto', Helvetica, sans-serif;
  text-align: left;
  color: var(--input-placeholder-color);
  padding: 0 !important;
  margin: var(--fix-transform-wiggle-for-label-margin) !important;
  position: relative;
  bottom: 26px;
  left: 4px;
  transition: all var(--label-transition-animation-time) ease-in-out;
  user-select: none !important;
  cursor: text !important;
}

.animated-label[for="email"] {
  padding-left: 0.06em !important;
}

input:focus+.animated-label {
  cursor: default !important;
  font-size: var(--font-small);
  bottom: 50px;
  left: 4px;
  transition: all var(--label-transition-animation-time) ease-in-out;
  color: var(--input-highlighted-color);
}

/* Login Inputs - Invalid and Errored Inputs */
.hkjs--touched:invalid:focus+.animated-label,
.hkjs--touched:not(:focus):placeholder-shown+.animated-label,
.hkjs--touched:invalid:not(:placeholder-shown)+.animated-label {
  color: var(--input-error-color);
}

input[type="text"]:not(:focus):not(:placeholder-shown)+.animated-label,
.hkjs--touched:not(:focus):not(:placeholder-shown)+.animated-label {
  cursor: default !important;
  font-size: var(--font-small);
  bottom: 50px;
  left: 4px;
  transition: all var(--label-transition-animation-time) ease-in-out;
}

.hkjs--touched:invalid:focus:placeholder-shown,
.hkjs--touched:not(:focus):placeholder-shown,
.hkjs--touched:invalid:not(:placeholder-shown) .hkjs--touched:not(:focus):not(.userLabel):placeholder-shown {
  box-shadow: 0px 1.5px 0px 0px var(--input-error-color);
  caret-color: var(--input-error-color);
}

/* Initial input focus */
.hkjs--untouched:focus:placeholder-shown {
  box-shadow: 0px 1.5px 0px 0px var(--input-highlighted-color) !important;
  caret-color: var(--input-highlighted-color);
}

/* Error Messages which are displayed based on these conditions */
.hkjs--touched:not(:focus):placeholder-shown~.error-message,
.hkjs--touched:invalid:focus:placeholder-shown~.error-message,
.hkjs--touched:invalid:not(:placeholder-shown)~.invalid-email-format {
  display: block;
}

/* We hide the enterprise login page by default */
/* So we can load it later using the button */
#kc-form-enterprise-login,
div.kc-logo-text span {
  display: none;
}


.pf-c-form-control {
  padding: 0;
}

/* Page Styling */

.alert-warning {
  color: #363636;
  background-color: #f5f5f5;
  border-color: #8b8d8f;
}

.pf-c-alert.pf-m-inline {
  margin-bottom: 0.5rem;
  margin-bottom: var(--pf-global--spacer--sm);
  padding: 0.75rem 1.25rem;
  padding: var(--pf-global--spacer--sm) var(--pf-global--spacer--md);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
}

.pf-c-alert.pf-m-inline::before {
  display: none;
}

.pf-c-alert.pf-m-inline.pf-m-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.pf-c-alert.pf-m-inline.pf-m-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.pf-c-alert.pf-m-inline.pf-m-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.pf-c-alert.pf-m-inline .pf-c-alert__icon {
  margin-right: 0.75rem;
  font-size: 1.5rem;
  line-height: 1;
}

.pf-c-alert.pf-m-success .pf-c-alert__icon {
  color: #155724;
}

.pf-c-alert.pf-m-danger .pf-c-alert__icon {
  color: #721c24;
}

.pf-c-alert.pf-m-warning .pf-c-alert__icon {
  color: #856404;
}

.pf-c-alert__title {
  color: #000;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
}

.fa-exclamation-triangle {
  color: #FF8C00;
}

#input-error-password-confirm,
#input-error-otp-code,
#input-error-otp-label,
#no-match-live-update-error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

/* why tf does keycloak display the attempted username and in such a weird way */
#kc-attempted-username {
  display: none !important;
}

/* irrelevant tiny button that creates an entire row of content empty with just a single tiny button */
#kc-login-tooltip {
  display: none !important;
}

#cancel-button {
  color: var(--primary-button-color) !important;
  text-transform: none;
  transition: all .2s ease-in-out;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: 4px;
  width: fit-content;
  display: inline-block;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: var(--pf-global--spacer--form-element) var(--pf-global--spacer--md);
}

#cancel-button:hover {
  border: none;
  outline: none;
  text-decoration: none;
  color: inherit;
}


.pf-c-button {
  background-color: var(--primary-button-color);
  color: #000;
  text-transform: none;
  transition: all .2s ease-in-out;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: 4px;
  border: none !important;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 18%) !important;
  width: fit-content !important;
  display: inline-block !important;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: var(--pf-global--spacer--form-element) var(--pf-global--spacer--md) !important;
}

.pf-c-button:disabled,
.pf-c-button[disabled] {
  background: var(--primary-disabled-button-color);
  color: var(--primary-disabled-button-text-color);
}

.pf-c-button:focus {
  box-shadow: 0 0 0 3px rgba(0, 145, 218, .5);
}

/* default - IE compatibility */
.pf-m-primary {
  color: #FFFFFF;
  background-color: var(--primary-button-color);
  background-color: var(--pf-global--primary-color--100);
}

/* default - IE compatibility */
.pf-m-primary:hover {
  background-color: #004080;
  background-color: var(--pf-global--primary-color--200);
}

/* default - IE compatibility */
.pf-c-button.pf-m-control {
  border: solid 1px;
  border: solid var(--pf-global--BorderWidth--sm);
  border-color: rgba(230, 230, 230, 0.5);
}

h1#kc-page-title {
  margin-top: 10px;
}

#kc-locale ul {
  display: none;
  top: 20px;
  min-width: 100px;
  padding: 0;
}

#kc-locale:hover ul {
  display: block;
}

/* IE compatibility */
#kc-locale-dropdown a {
  color: #6A6E73;
  color: var(--pf-global--Color--200);
  text-align: right;
  font-size: 14px;
  font-size: var(--pf-global--FontSize--sm);
}

/* IE compatibility */
a#kc-current-locale-link::after {
  content: "\2c5";
  margin-left: 4px;
  margin-left: var(--pf-global--spacer--xs)
}

.login-pf .container {
  padding-top: 40px;
}

.login-pf a:hover {
  color: #0099d3;
}

#kc-logo {
  width: 100%;
}

#kc-logo-wrapper {
  background-image: url(../img/keycloak-logo-2.png);
  background-repeat: no-repeat;
  height: 63px;
  width: 300px;
  margin: 62px auto 0;
}

div.kc-logo-text {
  background-image: url(../img/keycloak-logo-text.png);
  background-repeat: no-repeat;
  height: 63px;
  width: 300px;
  margin: 0 auto;
}

#kc-header {
  color: #ededed;
  overflow: visible;
  white-space: nowrap;
}

#kc-header-wrapper {
  font-size: 29px;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.2em;
  padding: 62px 10px 20px;
  white-space: normal;
}

#kc-content {
  width: 100%;
}

#kc-attempted-username {
  font-size: 20px;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-weight: normal;
  padding-right: 10px;
}

#kc-username {
  text-align: center;
  margin-bottom: -10px;
}

#kc-webauthn-settings-form {
  padding-top: 8px;
}

#kc-content-wrapper {
  margin-top: 20px;
}

#kc-form-wrapper {
  margin-top: 45px;
}

#kc-info {
  margin: 20px -40px -30px;
}

#kc-info-wrapper {
  font-size: 13px;
  padding: 15px 35px;
  background-color: #F0F0F0;
}

#kc-form-options span {
  display: block;
}

#kc-form-options .checkbox {
  margin-top: 0;
  color: #72767b;
}

#kc-terms-text {
  margin-bottom: 20px;
}

#kc-registration {
  margin-bottom: 0;
}

#cancel-button {
  background-color: var(--secondary-button-color);
}

#cancel-button:hover {
  background-color: var(--secondary-button-hovered);
  color: #fff;
}

/* TOTP */

.subtitle {
  text-align: right;
  margin-top: 30px;
  color: #909090;
}

.required {
  color: #A30000;
  /* default - IE compatibility */
  color: var(--pf-global--danger-color--200);
}

.kc-totp-settings {
  display: flex;
  margin: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
}

.secret-sso-link {
  display: inline-block;
  color: palevioletred;
  padding: 5px;
  margin-bottom: 2px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.sso-step-title {
  font-size: 20px;
  font-weight: bold;
}

.secret-sso-link:hover {
  background-color: var(--secondary-button-hovered);
  font-weight: 600;
}

.sso-step-text {
  font-size: 12px;
  color: black;
  padding: 5px;
  text-align: center;
}

ul#kc-totp-supported-apps {
  margin-bottom: 4px;
}

#kc-totp-secret-qr-code {
  max-width: 150px;
  max-height: 150px;
}

#kc-totp-secret-key {
  background-color: #fff;
  color: #333333;
  font-size: 16px;
  padding: 10px 0;
  padding-top: 5px;
}

/* OAuth */

#kc-oauth h3 {
  margin-top: 0;
}

#kc-oauth ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#kc-oauth ul li {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 12px;
  padding: 10px 0;
}

#kc-oauth ul li:first-of-type {
  border-top: 0;
}

#kc-oauth .kc-role {
  display: inline-block;
  width: 50%;
}

/* Code */
#kc-code textarea {
  width: 100%;
  height: 8em;
}

/* Social */
.kc-social-links {
  margin-top: 20px;
}

.kc-social-provider-logo {
  font-size: 23px;
  width: 30px;
  height: 25px;
  float: left;
}

.kc-social-gray {
  color: #737679;
  /* default - IE compatibility */
  color: var(--pf-global--Color--200);
}

.kc-social-item {
  margin-bottom: 0.5rem;
  /* default - IE compatibility */
  margin-bottom: var(--pf-global--spacer--sm);
  font-size: 15px;
  text-align: center;
}

.kc-social-provider-name {
  position: relative;
  top: 3px;
}

.kc-social-icon-text {
  left: -15px;
}

.kc-social-grid {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  grid-column-end: span 6;
  --pf-l-grid__item--GridColumnEnd: span 6;
}

.kc-social-grid .kc-social-icon-text {
  left: -10px;
}

.kc-login-tooltip {
  position: relative;
  display: none;
}

.kc-social-section {
  text-align: center;
}

.kc-social-section hr {
  margin-bottom: 10px
}

.kc-login-tooltip .kc-tooltip-text {
  top: -3px;
  left: 160%;
  background-color: black;
  visibility: hidden;
  color: #fff;

  min-width: 130px;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.6);
  padding: 5px;

  position: absolute;
  opacity: 0;
  transition: opacity 0.5s;
}

/* Show tooltip */
.kc-login-tooltip:hover .kc-tooltip-text {
  visibility: visible;
  opacity: 0.7;
}

/* Arrow for tooltip */
.kc-login-tooltip .kc-tooltip-text::after {
  content: " ";
  position: absolute;
  top: 15px;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

@media (min-width: 768px) {
  #kc-container-wrapper {
    position: absolute;
    width: 100%;
  }

  .login-pf .container {
    padding-right: 80px;
  }

  #kc-locale {
    position: relative;
    text-align: right;
    z-index: 9999;
  }
}

@media (max-width: 767px) {

  .login-pf body {
    background: #fff;
  }

  #kc-header {
    padding-left: 15px;
    padding-right: 15px;
    float: none;
    text-align: left;
  }

  #kc-header-wrapper {
    font-size: 16px;
    font-weight: bold;
    padding: 20px 60px 0 0;
    color: #72767b;
    letter-spacing: 0;
  }

  div.kc-logo-text {
    margin: 0;
    width: 150px;
    height: 32px;
    background-size: 100%;
  }

  #kc-form {
    float: none;
  }

  #kc-info-wrapper {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
  }

  .login-pf .container {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #kc-locale {
    position: absolute;
    width: 200px;
    top: 20px;
    right: 20px;
    text-align: right;
    z-index: 9999;
  }

  #kc-logo-wrapper {
    background-size: 100px 21px;
    height: 21px;
    width: 100px;
    margin: 20px 0 0 20px;
  }

}

@media (min-height: 646px) {
  #kc-container-wrapper {
    bottom: 12%;
  }
}

@media (max-height: 645px) {
  #kc-container-wrapper {
    padding-top: 50px;
    top: 20%;
  }
}

.card-pf form.form-actions .btn {
  float: right;
  margin-left: 10px;
}

#kc-form-buttons {
  margin-top: 20px;
}

.login-pf-page .login-pf-brand {
  margin-top: 20px;
  max-width: 360px;
  width: 40%;
}

/* Internet Explorer 11 compatibility workaround for select-authenticator screen */
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .select-auth-box-parent {
    border-top: 1px solid #f0f0f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
  }

  .select-auth-box-headline {
    font-size: 16px;
    color: #06c;
    font-weight: bold;
  }

  .select-auth-box-desc {
    font-size: 14px;
  }

  .pf-l-stack {
    flex-basis: 100%;
  }
}

/* End of IE11 workaround for select-authenticator screen */

.select-auth-box-arrow {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.select-auth-box-icon {
  display: flex;
  flex: 0 0 2em;
  justify-content: center;
  margin-right: 1rem;
  margin-left: 3rem;
}

.select-auth-box-parent {
  border-top: 1px solid var(--pf-global--palette--black-200);
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.select-auth-box-parent:hover {
  background-color: #f7f8f8;
}

.select-auth-container {
  padding-bottom: 0px !important;
}

.select-auth-box-headline {
  font-size: var(--pf-global--FontSize--md);
  color: var(--pf-global--primary-color--100);
  font-weight: bold;
}

.select-auth-box-desc {
  font-size: var(--pf-global--FontSize--sm);
}

.card-pf {
  margin: 0 auto;
  box-shadow: var(--pf-global--BoxShadow--lg);
  padding: 0 20px;
  max-width: 450px;
  border-top: 4px solid;
  border-color: #0066CC;
  /* default - IE compatibility */
  border-color: var(--pf-global--primary-color--100);
  border-radius: 5px;
}

/*phone*/
@media (max-width: 767px) {
  .login-pf-page .card-pf {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    border-top: 0;
    box-shadow: 0 0;
  }

  .kc-social-grid {
    grid-column-end: 12;
    --pf-l-grid__item--GridColumnEnd: span 12;
  }

  .kc-social-grid .kc-social-icon-text {
    left: -15px;
  }
}

.login-pf-page .login-pf-signup {
  font-size: 15px;
  color: #72767b;
}

#kc-content-wrapper .row {
  margin-left: 0;
  margin-right: 0;
}

.login-pf-page.login-pf-page-accounts {
  margin-left: auto;
  margin-right: auto;
}

.login-pf-page .btn-primary {
  margin-top: 0;
}

.login-pf-page .list-view-pf .list-group-item {
  border-bottom: 1px solid #ededed;
}

.login-pf-page .list-view-pf-description {
  width: 100%;
}

#kc-form-login div.form-group:last-of-type,
#kc-register-form div.form-group:last-of-type,
#kc-update-profile-form div.form-group:last-of-type {
  margin-bottom: 0px;
}

div.form-group {
  margin-bottom: 2em;
}

div.space {
  font-size: 0;
  height: 15px;
  line-height: 0;
  background-color: transparent !important;
}

.no-bottom-margin {
  margin-bottom: 0;
}

#kc-back {
  margin-top: 5px;
}

/* Change/Update Password Stylings */

.pf-c-reset-password-rule-bar {
  padding-top: 5px;
}

.pf-c-reset-password-rule-checkbox {
  padding-top: 1px;
}

.progress {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;

  &.progress-xs {
    height: 5px;
    margin-top: 5px;
  }

  &.progress-sm {
    height: 10px;
    margin-top: 5px;
  }

  &.progress-lg {
    height: 25px;
  }

  &.vertical {
    position: relative;
    width: 20px;
    height: 200px;
    display: inline-block;
    margin-right: 10px;

    >.progress-bar {
      width: 100% !important;
      position: absolute;
      bottom: 0;
    }

    &.progress-xs {
      width: 5px;
      margin-top: 5px;
    }

    &.progress-sm {
      width: 10px;
      margin-top: 5px;
    }

    &.progress-lg {
      width: 30px;
    }
  }
}

.password-rule-label {
  pointer-events: none;
  font-size: var(--font-medium);
  font-weight: 400;
  font-family: 'Roboto', Helvetica, sans-serif;
  text-align: left;
  color: var(--input-placeholder-color);
  position: relative;
  bottom: 3px;
}

.progress-bar {
  background-color: #2196F3;
  box-shadow: none;

  &.text-left {
    text-align: left;

    span {
      margin-left: 10px;
    }
  }

  &.text-right {
    text-align: right;

    span {
      margin-right: 10px;
    }
  }
}

.check {
  display: inline;
  pointer-events: none;
}

.check:before {
  content: "";
  border-radius: 50%;
  background: rgba(34, 50, 84, 0.03);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check svg {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #cb336b;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}

.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.check:hover:before {
  opacity: 1;
}

.check:hover svg {
  stroke: #4285F4;
}

.rule-checkbox:checked+.check svg {
  stroke: #7b1c54;
}

.rule-checkbox:checked+.check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}

.rule-checkbox:checked+.check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}
